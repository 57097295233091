















import {Component, Vue} from 'vue-property-decorator'

@Component
export default class SignUpView extends Vue {
	public redirectTo(): void {
		window.location.href = window.location.origin + '/dashboard'
	}

	public redirectToRegister(): void {
		this.$keycloak.init({onLoad: 'check-sso'})
		this.$keycloak.register({redirectUri: window.location.origin + '/dashboard'})
	}
}
