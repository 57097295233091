

































































































import { getAPIConfiguration, stakeTermDeposit, TermDepositItem } from '@/clients/cpinblocks'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationRule } from '@/types'
import { Conf } from '@/models/Conf'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
	},
})
export default class AddStakeMB extends Vue {
	@Prop(Object) termDeposit!: TermDepositItem
	@Prop(Object) max!: BigNumber
	show2FADialog = false
	amountPrecision = 0
	creating = false
	loading = true
	amount: BigNumber = new BigNumber(0)
	amountNet: BigNumber = new BigNumber(0)
	fee: BigNumber = new BigNumber(0)
	min = 0
	private conf: Conf | null = null
	private isValid = false

	get amountRules (): ValidationRule {
		const result = [
			(v?: string) => !!v || this.$t('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
			(v: string) => {
				return convertValueToBigNumber(v).isLessThanOrEqualTo(this.max) || this.$t('addOffer.rule.max', {
					max: formatFixedDecimals(this.$i18n, this.max, this.amountPrecision),
				})
			},
		]
		result.push((v: string) => convertValueToBigNumber(v).isGreaterThan(this.min) || this.$t('addOffer.rule.minExcluded', {
			min: this.min,
		}) as string,)
		return result
	}

	onUpdateAmount (newAmount: string): void {
		if (newAmount) {
			this.amount = new BigNumber(newAmount)
			this.fee = this.amount.multipliedBy(this.termDeposit.conf.stakeFeePercentage).div(100)
			this.amountNet = this.amount.minus(this.fee)
		}
	}

	async created (): Promise<void> {
		this.conf = await getAPIConfiguration()
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		await this.reset()
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createStakeOperation (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			await stakeTermDeposit(this.$store.state.jwt, code2FA, this.termDeposit.conf.id, this.amount, this.amountNet)
			this.$emit('success', { amount: this.amount })
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
			return
		}
		this.creating = false
	}

	async reset (): Promise<void> {
		this.amount = new BigNumber('0')
		this.amountNet = new BigNumber('0')
		this.fee = new BigNumber('0')
	}
}
