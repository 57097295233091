


























import TermDeposit from '@/components/TermDeposit.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Account } from '@/models/Account'
import { Conf } from '@/models/Conf'
import { getAPIConfiguration, getAccounts, getTermDeposits, TermDepositItem } from '@/clients/cpinblocks'

@Component({
	components: {
		TermDeposit,
	},
})
export default class TermDepositView extends Vue {
	accounts: Account[] = []
	conf: Conf | null = null
	loading = true
	termDeposits: TermDepositItem[] = []

	async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		try {
			this.termDeposits = await getTermDeposits(this.$store.state.jwt)
		} catch (error) {
			this.termDeposits = []
		}
		this.loading = false
	}

	async onUpdate (): Promise<void> {
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		try {
			this.termDeposits = await getTermDeposits(this.$store.state.jwt)
		} catch (error) {
			this.termDeposits = []
		}
	}
}
