




















































import {getAPIConfiguration, TermDepositItem, unstakeMagicBarn, unstakeTermDeposit} from '@/clients/cpinblocks'
import { Conf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, formatFixedDecimals, convertValueToBigNumber } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { Currency } from '@/types'
import { ValidationRule } from '@/types'

@Component({
	components: { CurrencyInput, Code2FADialog },
})
export default class AddUnstakeMB extends Vue {
	@Prop(Object) termDeposit!: TermDepositItem

	show2FADialog = false
	private conf: Conf | null = null
	creating = false
	loading = true
	checkbox1 = false

	get finished (): boolean {
		return new Date(this.termDeposit.conf.endAtExclusive).getTime() < new Date().getTime()
	}

	get checkboxRequired (): boolean {
		return !this.finished
				&& this.termDeposit.conf.breakingMode !== 'INTERESTS_KEPT'
	}

	async created (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createUnstakeOperation (object: any): Promise<void> {
		const	code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			await unstakeTermDeposit(this.$store.state.jwt, code2FA, this.termDeposit.conf.id)
			this.$emit('success')
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
			return
		}
		this.creating = false
	}

}
