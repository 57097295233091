























































































































































import ClipboardCopy from '@/components/ClipboardCopy.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { TermDepositItem } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Staking } from '@/models/Staking'
import BigNumber from 'bignumber.js'
import AddStakeTD from '@/components/AddStakeTD.vue'
import AddUnstakeTD from '@/components/AddUnstakeTD.vue'
import { formatDate, formatFixedDecimalsNoUselessZero } from '@/utils'

@Component({
	components: { AddStakeTD, AddUnstakeTD, ClipboardCopy },
})
export default class TermDepositComponent extends Vue {
	@Prop() accounts!: Account[]
	@Prop() termDeposit!: TermDepositItem

	account: Account | null = null
	claimable: BigNumber | null = null
	enabledStake = false
	enabledUnstake = false
	loading = true
	max = new BigNumber('0')
	refreshFrequencyInMs = 100
	showStakeDialog = false
	showUnstakeDialog = false
	timer?: ReturnType<typeof setTimeout>
	stakeLoading = false
	staking: Staking | null = null
	unstakeLoading = false
	tableKey = 0

	get balance (): string {
		BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
		return this.account && this.account.balance ? this.account?.balance.toFixed(4) : '0'
	}

	get breakingModeLabel (): string {
		if (this.termDeposit.conf.breakingMode === 'FORBIDDEN') {
			return 'Not possible'
		} else if (this.termDeposit.conf.breakingMode === 'INTERESTS_LOST') {
			return 'Rewards loss'
		} else if (this.termDeposit.conf.breakingMode === 'INTERESTS_KEPT') {
			return 'Rewards kept'
		} else {
			return 'N/A'
		}
	}

	displayDate (date: string) {
		return formatDate(new Date(date), this.$i18n)
	}

	getCurrentReward (): string {
		if (this.nowIsBefore(this.termDeposit.conf.startAtInclusive)) {
			return '0'
		}
		if (this.termDeposit.at && this.termDeposit.interests && this.termDeposit.interestsPerSec && this.termDeposit.claimedInterests) {
			let decimalPlaces = 0
			for (let i = 18; i >= 0; i--) {
				if (new BigNumber(this.termDeposit.interestsPerSec).gte(new BigNumber(10).pow(-i))) {
					decimalPlaces = i
				}
			}
			let end = new Date().getTime()
			if (this.nowIsAfter(this.termDeposit.conf.endAtExclusive)) {
				end = new Date(this.termDeposit.conf.endAtExclusive).getTime()
			}
			let start = new Date(this.termDeposit.at).getTime()
			if (new Date(this.termDeposit.at).getTime() < new Date(this.termDeposit.conf.startAtInclusive).getTime()) {
				start = new Date(this.termDeposit.conf.startAtInclusive).getTime()
			}
			let delayMS = end - start
			if (delayMS < 0) {
				delayMS = 0
			}
			if (this.termDeposit.interestsPerSec === '0') {
				return formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(this.termDeposit.claimedInterests).plus(new BigNumber(this.termDeposit.interests)), 18)
			} else {
				return new BigNumber(this.termDeposit.claimedInterests).plus(new BigNumber(this.termDeposit.interests)).plus((new BigNumber(this.termDeposit.interestsPerSec).multipliedBy(delayMS / 1000))).toFixed(decimalPlaces)
			}
		}
		return '0'
	}

	countdown (d: string) : string {
		let delay = new Date(d).getTime() - new Date().getTime()
		if (delay < 0) {
			delay = -delay
		}
		delay = delay / 1000
		const nbDays = Math.floor(Math.abs(delay / 86400))
		const nbHours = Math.floor(Math.abs(delay / 3600) % 24).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 1 })
		const nbMinutes = Math.floor(Math.abs((delay / 60) % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
		const nbSeconds = Math.round(Math.abs(delay % 60)).toLocaleString(this.$store.state.locale, { minimumIntegerDigits: 2 })
		let result = ''
		if (nbDays > 0) {
			if (nbDays === 1) {
				result += nbDays + ' day '
			} else {
				result += nbDays + ' days '
			}
		}
		result += nbHours + ':' + nbMinutes + ':' + nbSeconds
		return result
	}

	nowIsAfter (d: string) : boolean {
		return new Date(d).getTime() < new Date().getTime()
	}

	nowIsBefore (d: string) : boolean {
		return !this.nowIsAfter(d)
	}

	async mounted (): Promise<void> {
		for (const acc of this.accounts) {
			if (acc.type.toLowerCase() === this.termDeposit.conf.type.toLowerCase() && acc.currency.toLowerCase() === this.termDeposit.conf.currency.toLowerCase()) {
				this.account = acc
			}
		}
		this.timer = setInterval(this.refresh, this.refreshFrequencyInMs)
		const cap = (new BigNumber(this.termDeposit.conf.hardCap).minus(new BigNumber(this.termDeposit.conf.balance))).dividedBy(new BigNumber(1).minus(new BigNumber(this.termDeposit.conf.stakeFeePercentage).dividedBy(100)))
		this.max = BigNumber.min(cap, new BigNumber(this.balance))
		this.loading = false
	}

	refresh () : void {
		this.tableKey++
		this.enabledStake = !(this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD')
			&& this.termDeposit.conf.enabled
			&& this.nowIsBefore(this.termDeposit.conf.subscriptionEndAtExclusive)
			&& this.nowIsAfter(this.termDeposit.conf.subscriptionStartAtInclusive)
			&& new BigNumber(this.max.toFixed(0)).gt(0)
		this.enabledUnstake = !(this.$store.state.owner.locked === 'HARD')
			&& this.termDeposit.conf.enabled
			&& !(this.termDeposit.conf.breakingMode === 'FORBIDDEN' && this.nowIsBefore(this.termDeposit.conf.endAtExclusive))
			&& this.termDeposit.amountNet !== undefined && new BigNumber(this.termDeposit.amountNet).gt(0)
	}

	beforeDestroy () {
		this.timer && clearInterval(this.timer)
	}

	openStakeDialog (): void {
		this.stakeLoading = true
		this.showStakeDialog = true
	}

	openUnstakeDialog (): void {
		this.unstakeLoading = true
		this.showUnstakeDialog = true
	}

	async onStake (event: any): Promise<void> {
		this.max = this.max.minus(event.amount)
		this.$emit('action')
		this.showStakeDialog = false
		this.stakeLoading = false
	}

	@Watch('accounts')
	refreshMax (): void {
	  for (const acc of this.accounts) {
		  if (acc.type.toLowerCase() === this.termDeposit.conf.type.toLowerCase() && acc.currency.toLowerCase() === this.termDeposit.conf.currency.toLowerCase()) {
			  this.account = acc
		  }
	  }
		const cap = (new BigNumber(this.termDeposit.conf.hardCap).minus(new BigNumber(this.termDeposit.conf.balance))).dividedBy(new BigNumber(1).minus(new BigNumber(this.termDeposit.conf.stakeFeePercentage).dividedBy(100)))
		this.max = BigNumber.min(cap, new BigNumber(this.balance))
	}

	async onUnstake (): Promise<void> {
		this.$emit('action')
		this.showUnstakeDialog = false
		this.unstakeLoading = false
	}
}
